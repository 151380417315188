import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', function() {

    const el = document.getElementById('cookies');
    const init = function() {
        const accept = el.getElementsByClassName('js-accept')[0];

        Cookies.get('fosfopower') != 1 ? el.classList.remove('move-out') : false;

        accept.addEventListener('click', function(e) {
            e.preventDefault();
            Cookies.set("fosfopower", 1, { expires: 356, path: '/' });
            el.classList.add('move-out');
        });
    };
    
    el ? init() : false;

}, false);
