import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

document.addEventListener('DOMContentLoaded',function () {

    window.openPrivPolicy = function(){
        document.getElementById('policyBtn').click();
        CookieConsent.hide();
        CookieConsent.hidePreferences();
    }

     CookieConsent.run({
        guiOptions: {
            consentModal: {
                layout: "box",
                position: "bottom left",
                equalWeightButtons: true,
                flipButtons: false
            },
            preferencesModal: {
                layout: "box",
                position: "right",
                equalWeightButtons: true,
                flipButtons: false
            }
        },
        categories: {
            necessary: {
                readOnly: true
            },
            analytics: {},
            marketing: {}
        },
        language: {
            default: "pl",
            autoDetect: "browser",
            translations: {
                pl: {
                    consentModal: {
                        title: "Używamy plików cookie",
                        description: '<p>Korzystamy z plików cookies i umożliwiamy zamieszczanie ich osobom trzecim. Pliki cookie pozwalają na poznanie Twoich preferencji na podstawie zachowań na stronie. Kontynuując korzystanie z&nbsp;serwisu, wyrażasz na to zgodę. Poznaj szczegóły i&nbsp;możliwości zmiany ustawień w&nbsp;<a href="javascript:openPrivPolicy()">Polityce Cookies</a>.</p>',
                        acceptAllBtn: 'Akceptuj wszystkie',
                        acceptNecessaryBtn: 'Odrzuć wszystkie',
                        showPreferencesBtn: 'Zarządzaj indywidualnymi preferencjami',
                        footer: ``,
                    },
                    preferencesModal: {
                        title: 'Zarządzaj indywidualnymi preferencjami',
                        acceptAllBtn: 'Akceptuj wszystkie',
                        acceptNecessaryBtn: 'Odrzuć wszystkie',
                        savePreferencesBtn: 'Akceptuje aktualne ustawienia',
                        closeIconLabel: 'Zamknij domyślne ustawienia',
                        serviceCounterLabel: "Service|Services",
                        sections: [
                            {
                                title: "Używamy plików cookie",
                                description: "<p>Korzystamy z plików cookies i umożliwiamy zamieszczanie ich osobom trzecim. Pliki cookie pozwalają na poznanie Twoich preferencji na podstawie zachowań na stronie. Kontynuując korzystanie z&nbsp;serwisu, wyrażasz na to zgodę. Poznaj szczegóły i&nbsp;możliwości zmiany ustawień w&nbsp;<a href=\"javascript:openPrivPolicy()\" >Polityce Cookies</a>.</p>"
                            },
                            {
                                title: 'Tylko niezbędne ciasteczka',
                                description: '<p>Niezbędne pliki cookie są absolutnie niezbędne do prawidłowego funkcjonowania witryny. Te pliki cookie zapewniają anonimowo podstawowe funkcje i zabezpieczenia witryny.</p>',
                                linkedCategory: "necessary"
                            },
                            {
                                title: 'Analityczne ciasteczka',
                                description: '<p>Analityczne pliki cookie służą do zrozumienia, w jaki sposób odwiedzający wchodzą w interakcję z witryną. Te pliki cookie pomagają dostarczać informacji na temat liczby odwiedzających, współczynnika odrzuceń, źródła ruchu itp.</p>',
                                linkedCategory: 'analytics'
                            }
                        ]
                    }
                }
            }
        }
    });

});
