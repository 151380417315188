import sendForm from "./form";

document.addEventListener('DOMContentLoaded', () => {

    const el = document.getElementById('contactFrm');
    if(!el)
        return;

    const requiredFields = {
        email: {
            presence: { message: "Pole nie może być puste" },
            email: { message: 'Adres nieprawidłowy' }
        },
        name_surname: {
            presence: { message: "Pole nie może być puste" },
            length: {
                maximum: 255,
                minimum: 3,
                message: 'Min. 3 znaki'
            },
            format: {
                pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                flags: "i",
                message: "Tylko znaki a-z i 0-9"
            }
        },
        phone: {
            // presence: { message: "Pole nie może być puste" },
            telCheck: "Podaj poprawny numer telefonu"
        },
        description: {
            // presence: { message: "Pole nie może być puste" },
            length: {
                maximum: 5000,
                minimum: 5,
                message: 'Min. 5 znaki'
            },
            format: {
                pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                flags: "i",
                message: "Tylko znaki a-z i 0-9"
            }
        }
    }

    function frmInit(){
        el.addEventListener('submit', function(e) {

            sendForm(el, requiredFields)
                .then(function (response) {
                    showSuccess("Dziękujemy za wysłanie wiadomości. Odpowiemy najszybciej jak to możliwe.");
                }).catch(function (error) {
                showError('Błąd podczas wysyłania formularza. Spróbuj ponownie później.');
                console.error(error);
            });
            e.preventDefault();
        });
    }
    frmInit();

    function showError(msg) {
        el.classList.add('is-msg');
        el.querySelector('.o-message').classList.remove('is-success');
        el.querySelector('.o-message').classList.add('is-error');
        el.querySelector('.o-message p').innerText = msg;
    }

    function showSuccess(msg) {
        el.classList.add('is-msg');
        el.querySelector('.o-message').classList.remove('is-error');
        el.querySelector('.o-message').classList.add('is-success');
        el.querySelector('.o-message p').innerText = msg;
    }

    el.querySelector('.o-message button').addEventListener('click', () => {
        el.classList.remove('is-msg');
        el.querySelector('.o-message').classList.remove('is-error');
        el.querySelector('.o-message').classList.remove('is-success');

    });

});
