let map = false;
let markers = [];
const key = 'ky97fdDzhGpAVrASWbBt';
document.addEventListener('DOMContentLoaded', () => {

    const el = document.getElementById('distributor-map');
    if(!el) return;

    const lat = el.dataset.lat;
    const lng = el.dataset.lng;

    let zoom =  14;
    if (!map) {
        map = new maplibregl.Map({
            container: 'distributor-map', // container id
            // style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${key}`, // style URL
            style: `https://api.maptiler.com/maps/bff4d3b2-b171-4192-85c1-402a05eeec9e/style.json?key=${key}`, // style URL

            // style: `https://api.maptiler.com/maps/09781b4f-3d4d-4f3a-bd4e-7aa69b1641f9?key=${key}`, // style URL
            center: [lng, lat], // starting position [lng, lat]
            zoom: zoom, // starting zoom
        });
        map.addControl(new maplibregl.NavigationControl(), 'bottom-right');
        map.scrollZoom.disable();
    } else {
        if (setCenter) {
            map.jumpTo({
                center: [lng, lat],
                zoom: zoom
            });
        }
    }

    let list = [];
    markers.forEach((item) => {
        item.remove();
    });


    let icon = document.createElement('div');
    icon.className =  'marker-point';// : 'marker-distribution icon-pin';

    let marker = new maplibregl.Marker(icon)
        .setLngLat([lng, lat])
        .addTo(map);

    markers.push(marker);


}, false);


