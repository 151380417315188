const makeSlug = function (string) {
    string = string.trim().toLowerCase();

    const polish = ['ą', 'ć', 'ę', 'ł', 'ń', 'ó', 'ś', 'ź', 'ż'];
    const replace = ['a', 'c', 'e', 'l', 'n', 'o', 's', 'z', 'z'];
    for (let i = 0; i < polish.length; i++) {
        string = string.replace(new RegExp(polish[i], 'g'), replace[i]);
    }

    string = string.replace(/[^a-z0-9]/g, '-');
    string = string.replace(/-+/g, '-');
    string = string.replace(/^-|-$/g, '');

    return string;
}

const distributorUrl = function (name,city){
    return 'dystrybutor/' + makeSlug(name+' '+ city);
    // return 'distributor.php?id=' + makeSlug(name);
}


export { makeSlug, distributorUrl };
