import validate from 'validate.js';
import {parsePhoneNumberFromString as parseMin} from 'libphonenumber-js/max'

export default function (form, requiredFields, successMessage) {

    validate.validators.telCheck = function(value, options, key, attributes) {

        if (value) {
            let phoneNumber = parseMin(value, 'PL');
            if(phoneNumber === undefined) {
                return options;
            }

            if (phoneNumber.isValid()) {

            } else {
                return options;
            }
        }
    };

    validate.validators.consentCheck = function(value, options, key, attributes) {

    };

    const inputs = form.querySelectorAll("input, textarea, select");

    inputs.forEach((input)=>{
        if(input.type === 'checkbox') {
            input.addEventListener('click',()=>{
                input.closest('.o-checkbox').classList.remove('has-error');
                input.closest('.o-checkbox').querySelector('.o-checkbox__error').innerText = '';
            })
        } else {
            input.addEventListener('focus', () => {
                input.closest('.c-field').classList.remove('has-error');
                input.closest('.c-field').querySelector('.c-field__error').innerText = '';
            })
        }
    });

    function sendForm(form) {
        return new Promise(function(resolve, reject) {
            const formData = new FormData(form);
            formData.append('email2','');
            let request = new XMLHttpRequest();
            const method = form.getAttribute("method");
            const action = form.getAttribute("action")
            request.open(method, action);

            request.send(formData);

            request.onload = function () {
                if (request.status == 200) {
                    let response = JSON.parse(request.response);
                    // console.log(response);
                    resolve(response);

                } else {
                    // console.error(request.response)
                    reject(request.response);
                }


            }
        });
    }

    function showErrors(form, errors) {
        console.log(inputs)
        inputs.forEach((input)=> {
            if(errors[input.name]) {
                let className = ".c-field";
                if(input.type === 'checkbox') {
                    className = ".c-checkbox";
                }

                input.closest(className).classList.add('has-error');
                input.closest(className).querySelector(className + '__error').innerText = errors[input.name][0];
            }
        });

    }

    const promise = new Promise(function (resolve, reject) {

            const errors = validate(form, requiredFields, { fullMessages: false });
            showErrors(form, errors || {});

            if (!errors) {
                form.classList.add('is-loading');
                sendForm(form).then(function(response){
                    resolve(response);

                    inputs.forEach((input) => {
                        input.value = '';
                        if(input.type === 'checkbox') input.checked = false;

                    });
                }).catch(function(error){
                    // alert('Błąd podczas wysyłania formularza. Spróbuj ponownie później.');
                    reject(error);
                }).finally(function(){
                    form.classList.remove('is-loading');
                });
            }


    });


    return promise;
}
