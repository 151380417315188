import { gsap, Power1 } from "gsap";
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

document.addEventListener('DOMContentLoaded',function() {

    const nav = document.getElementsByClassName('js-nav')[0],
          hamburger = document.getElementsByClassName('js-hamburger')[0],
          menu = document.getElementsByClassName('js-menu')[0],
          submenu_item = menu.querySelectorAll('.menu-has-children');

    const init = function() {

        window.hideMenu = function() {
            if ( document.getElementsByClassName('js-nav')[0].classList.contains('is-visible')) {
                document.getElementsByClassName('js-nav')[0].classList.remove('is-visible');
                document.getElementsByClassName('js-hamburger')[0].classList.remove('is-active');
                
                document.removeEventListener('click', clickOutside);
                document.documentElement.classList.remove('mobile-menu');
                
                setTimeout(function() {
                    document.getElementsByClassName('js-nav')[0].classList.remove('is-block');
                    document.getElementsByClassName('js-nav')[0].classList.remove('is-animated');
                }, 400);
                
                if (window.innerWidth <= 1140) {
                    enableBodyScroll(document.getElementsByClassName('js-menu')[0]);
                }
                
                for (let i = 0; i < submenu_item.length; i++) {
                    submenu_item[i].classList.remove('is-active')
                }
            }            
        };

        const showMenu = function() {
            
            nav.classList.add('is-block');
            hamburger.classList.add('is-active');
            document.documentElement.classList.add('mobile-menu');

            setTimeout(function() {
                nav.classList.add('is-visible');
                document.addEventListener('click', clickOutside);
                showLinks();
            }, 100);    
            
            const showLinks = function() {
                gsap.utils.toArray(".js-menu").forEach(function(elem) {            
                    const blocks = elem.querySelectorAll(".js-item"),
                          tl = gsap.timeline().delay(.1).eventCallback("onComplete", function() {
                              nav.classList.add('is-animated');
//                              nav.getElementsByClassName('is-active')[0].classList.add('is-animated');
                          })

                    .from(blocks, {
                        x: 50,
                        opacity: 0,
                        stagger: {
                            each: 0.1
                        }
                    });
                });
            };        

            if (window.innerWidth <= 1024) {
                disableBodyScroll(menu);
            }
        };

        const submenu = function(e) {        
            let item = e.currentTarget;
           
            e.stopPropagation();
            
            if (item.parentNode.classList.contains('is-active')) {
                item.parentNode.classList.remove('is-active');
            } else {
                item.parentNode.classList.add('is-active');
            }

            e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        }

        const toggleMenu = function(e) {
            nav.classList.contains('is-visible') ? hideMenu() : showMenu();
        };
        
        const clickOutside = function(e) {
            if (!e.target.closest('.js-nav')) {
                hideMenu();
        	}
        };
        

        // Click event
        
        for (let j = 0; j < submenu_item.length; j++) {
            submenu_item[j].querySelector('a').addEventListener('click', submenu);
        }

        hamburger.addEventListener('click', toggleMenu);

        // Hide menu on ESC

        document.addEventListener('keydown', function(evt) {
            // evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key == "Escape" || evt.key == "Esc");
            } else {
                isEscape = (evt.keyCode == 27);
            }
            if (isEscape) {
                hideMenu();
            }
        });
        
        
        const checkWindowSize = function() {
            
            if (window.innerWidth > 1100) {
                hideMenu();
                window.removeEventListener('resize', checkWindowSize);
            }
        };
        
        
        window.addEventListener('resize', checkWindowSize);

    };

    nav ? init() : false;

}, false);